import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useField
} from "@/components/FormBuilder/Helpers";

export const agentCreate: FormBlock[] = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "firstName",
            label: __getText("fields", "firstNameLabel"),
            placeholder: __getText("fields", "firstNameLabel"),
            required: true
          }),
          useTextField({
            key: "lastName",
            label: __getText("fields", "lastNameLabel"),
            placeholder: __getText("fields", "lastNameLabel"),
            required: true
          }),
          useTextField({
            key: "displayName",
            label: __getText("fields", "displayNameLabel"),
            placeholder: __getText("fields", "displayNameLabel"),
            required: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "email",
            label: __getText("fields", "emailFieldLabel"),
            placeholder: __getText("fields", "emailFieldLabel"),
            required: true,
            validations: [
              {
                method: "email",
                error: "invalid email"
              }
            ]
          }),
          useTextField({
            key: "phone.primary",
            label: __getText("fields", "primaryPhoneNumberLabel"),
            placeholder: __getText("fields", "primaryPhoneNumberLabel"),
            required: true
          }),
          useTextField({
            key: "phone.secondary",
            label: __getText("fields", "secondaryPhoneNumberLabel"),
            placeholder: __getText("fields", "secondaryPhoneNumberLabel"),
            required: true
          })
        ],
        { layout: "3-col" }
      )
    ],
    { title: "Personal Details" }
  ),
  ...useFormFieldGroup(
    [
      useField({
        key: "physicalAddress",
        label: __getText("fields", "physicalAddressLabel"),
        required: true,
        type: "address",
        addressType: "physicalAddress"
      })
    ],
    { layout: "full" }
  ),
  {
    element: "field",
    classList: ["mb-4"],
    properties: {
      key: "isPOBoXFormat",
      required: true,
      type: "radio_button_group",
      preamble: "Does mailing address have a P.O. BOX format?",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" }
      ]
    }
  },
  ...useFormFieldGroup(
    [
      useField({
        key: "mailingAddress",
        label: __getText("fields", "mailingAddressLabel"),
        required: true,
        type: "address",
        addressType: "mailingAddress",
        isPOBOXMailingAddress: "true"
      })
    ],
    { layout: "full" }
  ),
  ...useFormFieldGroup(
    [
      {
        element: "field",
        classList: ["mb-4 text-lg"],
        properties: {
          key: "companyIds",
          required: true,
          type: "check_box_group",
          preamble: "Select companies user is allowed to quote",
          options: []
        }
      }
    ],
    { layout: "full" }
  )
];
