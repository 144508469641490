import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock, FormBlockField } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useField,
  useTextField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";
import store from "@/store";
import { license } from "../shared/license";
import {
  extraAtlasAdminDepartment,
  extraAtlasAdminPosition,
  getUserPermissionsByRole
} from "@/helpers";

const getCurrentUser = store.getters["auth/getCurrentUser"];
import { IAdminUserData } from "@/store/modules/user/types";

export function adminUser(userData: IAdminUserData): FormBlock[] {
  const {
    showPhysicalAddress,
    showMailingAddress,
    agentSelected,
    disableAgencyField = false,
    role,
    agentName = "",
    agencySelected,
    isUserBanned = false
  } = userData;
  const agencyCodeReminder = `<p>User <b>${agentName}</b> will get access to all of the selected agencies' data (quotes, claims and change requests). <br> They'll also get access to their agent reports if that permission has been granted.</p>`;

  const editingUser = store.getters["user/getEditingUser"];
  const isUnderwriter = !!(
    editingUser?.role === "underwriting" &&
    editingUser.underWriterAgenciesData &&
    editingUser.underWriterAgenciesData.length
  );
  const arr = [
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useTextField({
              key: "firstName",
              label: __getText("fields", "firstNameLabel"),
              placeholder: __getText("fields", "firstNameLabel"),
              required: true
            }),
            useTextField({
              key: "lastName",
              label: __getText("fields", "lastNameLabel"),
              placeholder: __getText("fields", "lastNameLabel"),
              required: true
            }),
            useTextField({
              key: "displayName",
              label: __getText("fields", "displayNameLabel"),
              placeholder: __getText("fields", "displayNameLabel"),
              required: true
            })
          ],
          { layout: "3-col" }
        ),
        ...useFormFieldGroup(
          [
            useTextField({
              key: "email",
              label: __getText("fields", "emailFieldLabel"),
              placeholder: __getText("fields", "emailFieldLabel"),
              required: true,
              validations: [
                {
                  method: "email",
                  error: "invalid email"
                }
              ]
            }),
            useField({
              key: "phone.primary",
              label: __getText("fields", "primaryPhoneNumberLabel"),
              placeholder: __getText("fields", "primaryPhoneNumberLabel"),
              required: true,
              type: "phone"
            }),
            useField({
              key: "phone.secondary",
              label: __getText("fields", "secondaryPhoneNumberLabel"),
              placeholder: __getText("fields", "secondaryPhoneNumberLabel"),
              required: false,
              type: "phone"
            })
          ],
          { layout: "3-col" }
        )
      ],
      {
        title: "Personal Details",
        description: "Basic information about the user"
      }
    ),

    ...useFormSection([
      ...useFormFieldGroup(
        [
          useField({
            key: "role",
            label: __getText("fields", "roleLabel"),
            type: "select",
            options: [],
            required: true
          }),
          useField({
            key: "agencyId",
            label: __getText("fields", "agency"),
            type: "select-search-agencies",
            required:
              store.getters["user/getEditingUser"] &&
              ["agent", "agencyAdmin", "subAdmin", "underwriting"].includes(
                store.getters["user/getEditingUser"].role
              )
                ? true
                : false,
            disabled: disableAgencyField || isUserBanned,
            descriptionHTML:
              agencySelected && store.getters["auth/isCurrentUserAdmin"]
                ? "<p class='text-xs text-blue-800 mt-3 ml-2 cursor-pointer'>Click here to view agency</p>"
                : "",
            clearable: true,
            placeholder: "Enter more than 4 characters",
            conditions: {
              and: [
                {
                  operator: "not",
                  field: "role",
                  value: "underwriting"
                },
                {
                  operator: "not",
                  field: "role",
                  value: "adjuster"
                },
                {
                  operator: "not",
                  field: "role",
                  value: "ezlynx"
                }
              ]
            }
          }),
          ...selectAgenciesField("ezlynx", "ezlynxAgencies"),
          useField({
            key: "status",
            label: __getText("fields", "statusLabel"),
            type: "select",
            options: store.getters["auth/isCurrentUserAdmin"]
              ? selectOptions.userStatus
              : selectOptions.userStatusAgencyAdmin,
            required: true
          }),
          useField({
            key: "allAgencyCodes",
            label: "Agency Codes",
            type: "select",
            placeholder: "Agency Code",
            required: true,
            multiple: true,
            information: {
              title: "Please Note",
              body: agencyCodeReminder
            },
            conditions: {
              or: [
                {
                  operator: "equals",
                  field: "role",
                  value: "ezlynx"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "agent"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "admin"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "agencyAdmin"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "subAdmin"
                }
              ],
              and: [
                {
                  operator: "exists",
                  field: "agencyId",
                  value: true
                },
                {
                  operator: "not",
                  field: "role",
                  value: "underwriting"
                }
              ]
            }
          }),
          useTextField({
            key: "adjusterCode",
            label: "Adjuster Code",
            required: true,
            conditions: {
              and: [
                {
                  operator: "equals",
                  field: "role",
                  value: "adjuster"
                }
              ]
            }
          })
        ],
        { layout: "4-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "agentCode",
            label: __getText("fields", "agentCodeLabel"),
            type: "select",
            required: true,
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "agentCode",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "adjustingFirm",
            label: "Adjusting Firm",
            type: "select-search-adjuster-firms",
            required: true,
            conditions: {
              and: [
                {
                  operator: "equals",
                  field: "role",
                  value: "adjuster"
                }
              ]
            }
          })
        ],
        { layout: "2-col" }
      ),

      ...(isUnderwriter
        ? useFormSection(
            [
              ...useFormFieldGroup(
                [
                  {
                    element: "field",
                    properties: {
                      key: "underWriterAgencies",

                      type: "check_box_group_block"
                    }
                  }
                ],
                { layout: "full" }
              )
            ],
            {
              title: "Underwriters Agencies",
              description: "Select agencies for the underwriter"
            }
          )
        : []),
      ...useFormFieldGroup(
        [
          useField({
            key: "atlasAdmin.title",
            label: "Title",
            type: "select",
            required: true,
            conditions: {
              and: [
                {
                  operator: "equals",
                  field: "isAtlasAdmin",
                  value: true
                }
              ]
            },
            options: extraAtlasAdminPosition(),
            allowCreate: true,
            filterable: true
          }),
          useField({
            key: "atlasAdmin.department",
            label: "Department",
            type: "select",
            required: true,
            conditions: {
              and: [
                {
                  operator: "equals",
                  field: "isAtlasAdmin",
                  value: true
                }
              ]
            },
            options: extraAtlasAdminDepartment(),
            allowCreate: true,
            filterable: true
          })
        ],
        { layout: "3-col" }
      )
    ])
  ];
  if (showPhysicalAddress && agentSelected) {
    arr.splice(2, 0, ...userPhysicalAddress);
  }
  if (showMailingAddress && agentSelected) {
    arr.splice(3, 0, ...userMailingAddress);
  }
  if (
    store.getters["user/getEditingUser"] &&
    (((store.getters["user/getEditingUser"].agencyId ||
      (!store.getters["user/getEditingUser"].agencyId &&
        store.getters["user/getEditingUser"].companyIds.length)) &&
      store.getters["user/getEditingUser"].role === "admin") ||
      ["agent", "underwriting", "agencyAdmin", "subAdmin"].includes(
        store.getters["user/getEditingUser"].role
      ))
  ) {
    arr.splice(
      2,
      0,
      ...useFormSection(
        [
          ...useFormFieldGroup(
            [
              {
                element: "field",
                classList: ["mb-4"],
                properties: {
                  key: "companyIds",
                  required: ["agent", "agencyAdmin", "subAdmin"].includes(
                    store.getters["user/getEditingUser"].role
                  ),
                  type: "check_box_group_block",
                  options: []
                }
              }
            ],
            { layout: "full" }
          )
        ],
        {
          title: "Companies",
          description: "Select companies user is allowed to quote"
        }
      )
    );
  }
  if (!agentSelected && role !== "adjuster") {
    arr.splice(3, 0, ...editingAddressFields);
  }

  if (agentSelected || role === "agencyAdmin" || role === "subAdmin") {
    arr.splice(2, 0, ...license(true));
  }
  if (role !== "adjuster" && role !== "ezlynx") {
    arr.push(...permissionsSection(/*role*/));
  }
  return arr;
}

export function permissionsSection(): FormBlock[] {
  // editingRole: IUserRoleStrings | undefined
  return [
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            {
              element: "field",
              classList: [],
              properties: {
                key: "disabledPermissions",
                required: false,
                type: "permissions-editor",
                options: getUserPermissionsByRole(getCurrentUser?.role),
                conditions: {
                  and: [
                    {
                      operator: "not",
                      field: "role",
                      value: "adjuster"
                    }
                  ]
                }
              }
            }
          ],
          { layout: "full" }
        )
      ],
      {
        title: "User Permissions",
        description:
          "Check/Uncheck permissions to enable/disable these features"
      }
    )
  ];
}

export const editingAddressFields: FormBlock[] = [
  useField({
    key: "physicalAddress",
    label: ["fields", "physicalAddressLabel"],
    required: true,
    type: "address",
    addressType: "physicalAddress",
    conditions: {
      and: [
        {
          operator: "equals",
          field: "role",
          value: "adjuster"
        }
      ]
    }
  }),
  useField({
    key: "mailingAddress",
    label: __getText("fields", "mailingAddressLabel"),
    required: true,
    type: "address",
    addressType: "mailingAddress",
    isPOBOXMailingAddress: "true",
    allowSyncingWithPhysicalAddress: true,
    conditions: {
      and: [
        {
          operator: "equals",
          field: "role",
          value: "adjuster"
        }
      ]
    }
  })
];

export const userPhysicalAddress: FormBlock[] = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "physicalAddress.houseNumber",
            label: "House number",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "physicalAddress.streetName",
            label: "Street",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "physicalAddress.city",
            label: "City",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "physicalAddress.zipCode",
            label: "Zip",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "physicalAddress.state",
            label: "State",
            placeholder: "",
            type: "dataview"
          })
        ],
        { layout: "2-col" }
      )
    ],
    {
      title: "Physical Address"
    }
  )
];

export const userMailingAddress: FormBlock[] = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "mailingAddress.houseNumber",
            label: "House number",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "mailingAddress.streetName",
            label: "Street",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "mailingAddress.city",
            label: "City",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "mailingAddress.zipCode",
            label: "Zip",
            placeholder: "",
            type: "dataview"
          }),
          useField({
            key: "mailingAddress.state",
            label: "State",
            placeholder: "",
            type: "dataview"
          })
        ],
        { layout: "2-col" }
      )
    ],
    {
      title: "Mailing Address"
    }
  )
];

function selectAgenciesField(role: string, key: string): FormBlockField[] {
  return [
    useField({
      key,
      label: __getText("fields", "agency"),
      type: "select-search-agencies",
      required: false,
      multiple: true,
      placeholder: "Enter more than 4 characters",
      conditions: {
        and: [
          {
            operator: "equals",
            field: "role",
            value: role
          }
        ]
      }
    })
  ];
}
